<template>
  <div class="search-warpper formPart">
    <el-form :inline="true" :model="query" class="demo-form-inline">
      <el-form-item label="会员等级：">
        <el-select
          size="mini"
          @change="$emit('search')"
          style="width: 200px"
          v-model="query.userLevel"
          clearable
          placeholder="请选择会员等级搜索"
        >
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分销商名称：">
        <el-input
          @change="$emit('search')"
          size="mini"
          style="width: 240px"
          clearable
          placeholder="请输入分销商名称"
          v-model="query.nickName"
        ></el-input>
      </el-form-item>
      <el-form-item label="登录账号：">
        <el-input
          @change="$emit('search')"
          size="mini"
          clearable
          style="width: 240px"
          placeholder="请输入登录账号"
          v-model="query.accountName"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          style="height: 32px; width: 48px; padding: 0; margin: 0 0 5px 17px"
          type="primary"
          @click="$emit('search')"
          icon="el-icon-search"
        ></el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getVipList } from '@/api/vipLevel'
export default {
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: []
    }
  },
  async created() {
    //请求调用会员等级接口，将detail赋值给options
    try {
      const page = { pageIndex: 1, pageSize: 0 }
      const { code, detail } = await getVipList({ page })
      if (code == 0) {
        this.options = detail.map((item) => ({
          label: item.levelName,
          value: item.id
        }))
      }
    } catch (error) {}
  }
}
</script>

<style lang="scss" scoped>
.formPart {
  margin: 20px 0;
  background-color: #f7f8fa;
  padding: 20px 20px 5px;
  .el-form-item {
    margin-right: 20px;
  }
}
</style>
