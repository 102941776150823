/* eslint-disable */
//存放vip等级的接口
import request from '@/service/request'

//添加会员
export function addVip(data) {
  return refreshDic(
    request({
      url: '/externaladmin/financeService/createMemberLevel',
      method: 'post',
      data
    }),
    'memberLevelList'
  )
}

//启用/禁用会员
export function changeVipState(data) {
  return refreshDic(
    request({
      url: '/externaladmin/financeService/updateEnabled',
      method: 'post',
      data
    }),
    'memberLevelList'
  )
}

//获得会员等级列表
export function getVipList(data = {}) {
  return request({
    url: '/externaladmin/financeService/memberLevelList',
    method: 'post',
    data
  })
}

//修改会员信息
export function updateVip(data) {
  return refreshDic(
    request({
      url: '/externaladmin/financeService/updateLevel',
      method: 'post',
      data
    }),
    'memberLevelList'
  )
}

//批量修改会员等级
export function changeVipLevel(data) {
  return refreshDic(
    request({
      url: '/externaladmin/financeService/memberLevel/updateUserLevel',
      method: 'post',
      data
    }),
    'memberLevelList'
  )
}

//设置会员等级折扣和区间
export function setVipDiscount(data) {
  return refreshDic(
    request({
      url: '/externaladmin/financeService/updateMemberLevel',
      method: 'post',
      data
    }),
    'memberLevelList'
  )
}

//查看会员历史记录
export function getHistory(data) {
  return request({
    url: '/externaladmin/financeService/updateLevelRecordList',
    method: 'post',
    data
  })
}

// 获取会员个人信息
export function getVipUserById(data) {
  return request({
    url: '/externaladmin/authService/user/getUserById',
    method: 'post',
    data
  })
}
// 变更用户会员等级
export function changeUserVipLevel(data = {}) {
  return request({
    url: '/externaladmin/financeService/paymentAccount/updateUserMemberLevel',
    method: 'post',
    data
  })
}
